<template>
  <div>
    <!-- Show the header option -->
    <div class="d-flex justify-end mb-6">
      <!-- Show the refresh button -->
      <v-btn
        depressed
        color="primary"
        :loading="isMakingRequest"
        :disabled="isMakingRequest"
        @click="handleRefreshRequest"
      >
        <v-icon left>
          refresh
        </v-icon>

        Refresh
      </v-btn>
    </div>

    <!-- In case there are no tasks yet -->
    <template
      v-if="posts.length === 0"
    >
      <v-subheader class="d-flex justify-space-between primary white--text">
        <span>
          Posts
        </span>

        <span>
          0 posts
        </span>
      </v-subheader>

      <div
        class="white mt-0 px-4 d-flex justify-center"
      >
        <!-- Show the animation here -->
        <div style="max-width: 360px" class="mx-auto">
          <lottie-animation
            loop
            file="132293-social-media-post.json"
          />
        </div>
      </div>
    </template>

    <!-- Otherwise -->
    <template v-else>

    </template>
  </div>
</template>

<script>
// Import children components
const LottieAnimation = () => import(/* webpackChunkName: "lottie-animation" */ "@/components/common/LottieAnimation.vue")

// Export the SFC
export default {
  // Name of the component
  name: "InfluencerOutreachPosts",

  // Register children components
  components: {
    LottieAnimation
  },

  // Accept incoming data from parent
  props: {
    influencer: {
      type: Object,
      required: true
    },

    data: {
      type: Object,
      required: true
    }
  },

  // Define local data variables
  data: () => ({
    // Whether or not if we're making a network request right now
    isMakingRequest: false,
  }),

  // Define readonly computable variables
  computed: {
    /**
     * Get all the emails related to this influencer
     *
     * @returns {Array}
     */
    posts() {
      return this.data.posts.filter((search) => search.campaign_report_influencer_id === this.influencer.id)
    }
  },

  // Define local method functions
  methods: {
    /**
     * Refresh all the email database again
     *
     * @returns {void}
     */
    handleRefreshRequest() {

    }
  },
}
</script>
